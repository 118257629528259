import React from "react"
import { CSSTransition } from "react-transition-group"
import styled from "styled-components"
import "../assets/css/overlayStyles.css"

const Wrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 100;
  pointer-events: none;
`

// const Overlay = styled.div`
//   position: absolute;
//   top: 0px;
//   left: 0px;
//   background: ${(props) => props.theme.background};
//   width: 100%;
//   height: 100%;
//   z-index: ${(props) => props.theme.zIndex};
//   opacity: ${(props) => props.theme.opacity};
// `

// Overlay.defaultProps = {
//     theme: {
//       background: "white",
//       zIndex: 18,
//       opacity: 0.2
//     },
//   }

class SlideOverlay extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      inProp: true,
      wasHidden: false,
    }
    if (props.overlayRefs) {
      props.overlayRefs.current.push(this)
      this.id = props.overlayRefs.current.length - 1
    }
  }
  componentDidMount() {
    const overlay = this
    if (this.props.startHidden && !this.state.wasHidden) {
      setTimeout(() => {
        overlay.setState({ inProp: false, wastHidden: true })
      }, 200)
    }
  }
  render() {
    if (!this.props.overlayRefs) return null
    return (
      <Wrapper>
        <CSSTransition
          key={this.id}
          in={this.state.inProp}
          timeout={500}
          classNames="overlay"
          transitionEnter={false}
        >
          <div style={{ width: "100%", height: "100%" }}>
            <div key={this.id} className={"overlay"}></div>
          </div>
        </CSSTransition>
      </Wrapper>
    )
  }
}

export default SlideOverlay
