/* eslint-disable import/no-extraneous-dependencies */
import React from "react"
import ReactFullpage from "@fullpage/react-fullpage"
import Nav from "../components/nav"
import Seo from "../components/seo"
import SlideOverlay from "../components/slideOverlay"
import FullPageStyle from "../assets/fullPage"
import { ThemeProvider } from "@material-ui/core/styles"
import { secondaryTheme } from "../utils/theme"

import {
  Title,
  Language,
  EnglishDetail,
  GermanDetail,
  Playschool,
  Translation,
  AboutUs,
} from "../components/indexSections/index"

const fullpageOptions = {
  anchors: ["dobrodosli", "tecajevi", "igraonica", "prijevod", "kontakt"],
  // sectionsColor: ["#282c34", "#ff5f45", "#0798ec"],
  callbacks: ["onLeave", "afterLoad"],

  navigation: true,
  navigationPosition: "left",
  navigationTooltips: false,
  slidesNavPosition: "bottom",
  // showActiveTooltip: true,
  scrollingSpeed: 600,
}

class IndexPage extends React.Component {
  constructor(props) {
    super(props)

    this.overlayRefs = React.createRef([])
    this.overlayRefs.current = []
    // access an item's ref using itemsRef.get(someItem)
    // set an item's ref using itemsRef.current.set(someItem)
  }

  render() {
    return (
      <ThemeProvider theme={secondaryTheme}>
        <Nav ref={(nav) => (window.nav = nav)} />
        <Seo title="Naslovnica" />
        <ReactFullpage
          ref={(fullpage) => (window.fullpage = fullpage)}
          {...fullpageOptions}
          afterLoad={(origin, destination, direction) => {
            // console.log(origin, destination, direction)
            // console.log(this)

            var outOverlay = this.overlayRefs.current[origin.index]
            var inOverlay = this.overlayRefs.current[destination.index]

            // console.log(origin.index, destination.index)
            // console.log(outOverlay.id, inOverlay.id)
            inOverlay.setState({ inProp: false })
            outOverlay.setState({ inProp: true })
          }}
          onLeave={(origin, destination, direction) => {
            //  flip navbar state
            window.nav[direction === "up" ? "show" : "hide"]()

            // can't scroll to 1st section
            // if (destination.index == 0) {
            //   return false
            // }
          }}
          render={({ state, fullpageApi }) => {
            return (
              <div id="fullpage-wrapper">
                <div className="section section1">
                  <Title
                    fullpageApi={fullpageApi}
                    overlayRefs={this.overlayRefs}
                  />
                </div>
                <div className="section">
                  <div className="slide">
                    <Language fullpageApi={fullpageApi} />
                  </div>
                  <div className="slide">
                    <EnglishDetail fullpageApi={fullpageApi} />
                  </div>
                  <div className="slide">
                    <GermanDetail fullpageApi={fullpageApi} />
                  </div>

                  <SlideOverlay overlayRefs={this.overlayRefs} />
                </div>
                <div className="section">
                  <Playschool
                    fullpageApi={fullpageApi}
                    overlayRefs={this.overlayRefs}
                  />
                </div>
                <div className="section">
                  <Translation
                    fullpageApi={fullpageApi}
                    overlayRefs={this.overlayRefs}
                  />
                </div>
                <div className="section">
                  <AboutUs
                    fullpageApi={fullpageApi}
                    overlayRefs={this.overlayRefs}
                  />
                </div>
              </div>
            )
          }}
        />
        <FullPageStyle />
        {/* <Preloader /> */}
      </ThemeProvider>
    )
  }
}

export default IndexPage
