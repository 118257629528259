import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import PaperSection from "../paperSection"
import { Typography, Grid } from "@material-ui/core"
import { Button } from "gatsby-theme-material-ui"

export const Playschool = ({ fullpageApi, overlayRefs }) => {
  const image = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "gloria/playschool/igraonica_5.jpg" }) {
        childImageSharp {
          fluid(
            maxWidth: 2400
            srcSetBreakpoints: [200, 340, 520, 890, 1200]
            quality: 80
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `).file
  return (
    <PaperSection
      sizes={{ xs: 8, sm: 7, md: 6 }}
      image={image}
      overlayRefs={overlayRefs}
    >
      <Typography align="center" variant="h4">
        Dječja igraonica
      </Typography>
      <Typography paragraph align="center" variant="subtitle1">
        Djeca od 4-7 godina
      </Typography>
      <Typography paragraph variant="body1" align="center">
        Kroz razne igre i aktivnosti učimo engleski ili njemački jezik kako bi
        djeca što lakše i brže naučila osnove jezika.
      </Typography>
      <Grid container justify="center" align="center">
        <Grid item xs={8} align="center">
          <Button fullWidth to="/igraonica" variant="contained" color="primary">
            Saznajte više
          </Button>
        </Grid>
      </Grid>
    </PaperSection>
  )
}
