import React from "react"
import styled, { keyframes } from "styled-components"
import Img from "gatsby-image"
import SlideOverlay from "./slideOverlay"
import patternUrl, { ReactComponent as Pattern } from "../images/pattern1.svg"
import { Paper, Grid, Box } from "@material-ui/core"

const PaperSection = ({
  image,
  children,
  overlayRefs,
  startHidden = false,
  mp = { m: 0, p: 4 },
  sizes = { xs: 8 },
}) => {
  console.log("pattern", patternUrl)
  return (
    <div style={{ width: "100%", height: "100%", position: "relative" }}>
      {image?.childImageSharp?.fluid && (
        <Img
          style={{
            position: "absolute",
            top: 0,
            width: "100%",
            height: "100%",
            zIndex: -5,
          }}
          fluid={image.childImageSharp.fluid}
          loading={"eager"}
        />
      )}
      <div
        style={{
          width: "100%",
          height: "100%",
          background: `url(${patternUrl})`,
          backgroundRepeat: "repeat",
          backgroundSize: "20px 20px",
          opacity: 0.35,
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -1,
        }}
      ></div>
      <Grid
        container
        spacing={0}
        alignItems="center"
        justify="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item {...sizes}>
          <Paper elevation="2" style={{ background: "rgba(0,0,0,0.45)" }}>
            <Box {...mp}>{children}</Box>
          </Paper>
        </Grid>
      </Grid>
      <SlideOverlay overlayRefs={overlayRefs} startHidden={startHidden} />
    </div>
  )
}

export default PaperSection
