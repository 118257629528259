import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import PaperSection from "../../paperSection"
import { Typography, Grid } from "@material-ui/core"
import { Button } from "gatsby-theme-material-ui"

export const Language = ({ fullpageApi, overlayRefs }) => {
  const image = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "gloria/new_york_1.jpg" }) {
        childImageSharp {
          fluid(
            maxWidth: 2400
            srcSetBreakpoints: [200, 340, 520, 890, 1200]
            quality: 80
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `).file
  return (
    <PaperSection image={image} overlayRefs={overlayRefs}>
      <Typography align="center" variant="h4">
        TEČAJEVI STRANIH JEZIKA
      </Typography>
      <Typography variant="body1">
        <ul>
          <li> predškolski</li>
          <li> dječji</li>
          <li> odrasli…</li>
        </ul>
      </Typography>

      <Typography paragraph align="center" variant="h6">
        cijena 60 sati - 1500&nbsp;kn&nbsp;+&nbsp;PDV
      </Typography>
      <Grid container justify="center" align="center">
        <Grid item xs={8} align="center">
          <Button fullWidth to="/tecajevi" variant="contained" color="primary">
            Saznajte više
          </Button>
        </Grid>
      </Grid>
    </PaperSection>
  )
}
