import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import PaperSection from "../paperSection"
import { Typography, Grid } from "@material-ui/core"
import { Button } from "gatsby-theme-material-ui"

export const Translation = ({ fullpageApi, overlayRefs }) => {
  const image = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "gloria/polica_4.jpg" }) {
        childImageSharp {
          fluid(
            maxWidth: 2400
            traceSVG: { color: "#2A2A2A", threshold: 180 }
            srcSetBreakpoints: [200, 340, 520, 890, 1200]
            quality: 80
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `).file
  return (
    <PaperSection
      sizes={{ xs: 8, sm: 6, md: 5 }}
      image={image}
      overlayRefs={overlayRefs}
    >
      <Typography paragraph align="center" variant="h4">
        Prevođenje na sve jezike
      </Typography>
      <Typography paragraph variant="body1" align="center">
        Gloria nudi uslugu prevođenja svih stranih jezika na hrvatski, kao
        obratno.
      </Typography>
      <Typography variant="subtitle1" align="center">
        Cijena prevođenja:
      </Typography>
      <Typography paragraph variant="body1" align="center">
        100&nbsp;kn&nbsp;+&nbsp;PDV po&nbsp;stranici (1500&nbsp;znakova)
      </Typography>

      <Grid container justify="center" align="center">
        <Grid item xs={8} align="center">
          <Button fullWidth to="/prijevodi" variant="contained" color="primary">
            Saznajte više
          </Button>
        </Grid>
      </Grid>
    </PaperSection>
  )
}
