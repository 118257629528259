import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import PaperSection from "../../paperSection"
import { Typography, Grid } from "@material-ui/core"
import { Button } from "gatsby-theme-material-ui"

export const EnglishDetail = ({ fullpageApi, overlayRefs }) => {
  const image = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "gloria/london_1.jpg" }) {
        childImageSharp {
          fluid(
            maxWidth: 2400
            srcSetBreakpoints: [200, 340, 520, 890, 1200]
            quality: 80
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `).file
  return (
    <PaperSection
      sizes={{ xs: 8, sm: 6, md: 5 }}
      image={image}
      overlayRefs={overlayRefs}
    >
      <Typography paragraph align="center" variant="h4">
        TEČAJ ENGLESKOG
      </Typography>
      <Typography align="center" variant="h6">
        UPISI TRAJU OD&nbsp;<b>1.9.</b>&nbsp;DO&nbsp;<b>28.9.</b>
      </Typography>
      <Typography paragraph variant="body1" align="center">
        <span>
          cijena 60 sati <b>1500&nbsp;kn&nbsp;+&nbsp;PDV</b>
        </span>
      </Typography>
      <Typography paragraph variant="body1" align="center">
        Nakon završenog tečaja polaznici dobiju Svjedodžbu o položenom ispitu i
        stupnju znanja
      </Typography>
      <Grid container justify="center" align="center">
        <Grid item xs={8} align="center">
          <Button fullWidth to="/tecajevi" variant="contained" color="primary">
            Saznajte više
          </Button>
        </Grid>
      </Grid>
    </PaperSection>
  )
}
