import React from "react"
import GoogleMapComponentWithMarker from "../map"
import {
  Box,
  Paper,
  Grid,
  ThemeProvider,
  Typography,
  Link,
} from "@material-ui/core"
import SlideOverlay from "../slideOverlay"

import { mainTheme } from "../../utils/theme"
import ContactForm from "../contactForm"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faLocationArrow,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons"

export const AboutUs = ({ fullpageApi, overlayRefs }) => {
  return (
    <>
      <Box
        p={4}
        style={{ width: "100%", height: "100%", background: "#a90000" }}
      >
      <ThemeProvider theme={mainTheme}>
        <Grid
          container
          spacing={0}
          align="center"
          justify="center"
          direction="column"
          style={{ width: "100%", height: "100%" }}
        >
          
          <Grid item container>
              <Grid item xs={12} md={6}>
                <Box px={1}>
                  <Paper elevation={2}>
                    <Box p={2}>
                      <Typography variant="h5">Kontaktirajte nas</Typography>
                      <Typography variant="subtitle2">
                        <FontAwesomeIcon icon={faLocationArrow} />{" "}
                        {"\t 43000 Bjelovar, J.J.Strossmayera 9"}
                      </Typography>
                      <Typography variant="subtitle2">
                        <Link href="tel:0038543241242" color="inherit">
                          <FontAwesomeIcon icon={faPhone} /> {"\t 043/241-242"}
                        </Link>
                      </Typography>
                      <Typography variant="subtitle2">
                        <Link href="mailto:gloria@bj.t-com.hr" color="inherit">
                          <FontAwesomeIcon icon={faEnvelope} />{" "}
                          {"\t gloria@bj.t-com.hr"}
                        </Link>
                      </Typography>

                      <ContactForm action="https://www.flexyform.com/f/fc3862c1b934bf5a861eacbcd59027faed0d36b4"></ContactForm>
                    </Box>
                  </Paper>
                </Box>
              </Grid>

            <Grid item xs={12} md={6}>
              <Box px={1} style={{ height: "100%", minHeight: "250px" }}>
                <GoogleMapComponentWithMarker
                  googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyCrw3DozzgAmMcO1Ol6VhdGZ6bmKmsZs70"
                  loadingElement={
                    <Paper
                      elevation={2}
                      color="#999"
                      style={{
                        height: `100%`,
                        width: "100%",
                        overflow: "hidden",
                      }}
                    />
                  }
                  containerElement={
                    <Paper
                      elevation={2}
                      style={{
                        height: `100%`,
                        width: "100%",
                      }}
                    />
                  }
                  mapElement={<div style={{ height: `100%` }} />}
                  zoom={16}
                  url={"https://goo.gl/maps/EuAP9P5S3eW8irL68"}
                  location={{
                    lat: 45.8982545, // latitude to position the marker
                    lng: 16.844672, // longitude to position the marker
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        
        </ThemeProvider>
      </Box>
      <SlideOverlay overlayRefs={overlayRefs} />
    </>
  )
}
