import React from "react"
import PaperSection from "../paperSection"

import { useStaticQuery, graphql } from "gatsby"
import { Typography, Button, Grid } from "@material-ui/core"

export const Title = ({ fullpageApi, overlayRefs }) => {
  const image = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "gloria/hodnik_1.jpg" }) {
        childImageSharp {
          fluid(
            maxWidth: 2400
            srcSetBreakpoints: [200, 340, 520, 890, 1200]
            quality: 80
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `).file

  const buttonRespProps = {
    xs: 8,
    sm: 5,
    md: 3,
  }

  return (
    <PaperSection image={image} overlayRefs={overlayRefs} startHidden>
      <Grid container direction="column" alignItems="center" spacing={2}>
        <Grid item md={8}>
          <Typography variant="h4" align="center">
            Dobrodošli na web stranice Gloria škole stranih jezika
          </Typography>
        </Grid>
        <Grid
          item
          container
          spacing={2}
          alignContent="center"
          alignItems="center"
          justify="center"
        >
          <Grid item {...buttonRespProps}>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              onClick={() => fullpageApi.moveSectionDown()}
            >
              Tečajevi
            </Button>
          </Grid>
          <Grid item {...buttonRespProps}>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              onClick={() => fullpageApi.moveTo(4)}
            >
              Prevođenje
            </Button>
          </Grid>
          {/* <Grid item {...buttonRespProps}>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              onClick={() => fullpageApi.moveSectionDown()}
            >
              Individualni Sati
            </Button>
          </Grid> */}
          <Grid item {...buttonRespProps}>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              onClick={() => fullpageApi.moveTo(5)}
            >
              Kako do nas
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </PaperSection>
  )
}
