import React from "react"

export default () => (
  <style type="text/css">
    {`
        #fp-nav {
            top: 30%
        }
        
        
        #fp-nav ul li a span,
        .fl-slidesNav ul li a span {
            width: 12px;
            height: 12px;
            background: white
        }
        
        #fp-nav ul li {
            margin: 18px 0px;
        }
        
        #fp-nav ul li:hover a.active span,
        #fp-nav ul li a.active span,
        .fp-slidesNav ul li:hover a.active span,
        .fp-slidesNav ul li a.active span {
            margin: -2px -2px;
            background: white
        }
        
        #fp-nav ul li:hover a span,
        .fp-slidesNav ul li:hover a span {
            width: 18px;
            height: 18px;
            margin: -4px -5px;
            background: white
        }
        
        #fp-nav ul li a.active span {
            background: #de002c;
        }
        
        .fp-controlArrow.fp-prev {
            top: auto;
            bottom: 40%;
            margin-top: -28px;
            border-width: 28.5px 24px 28.5px 0;
        }
        
        .fp-controlArrow.fp-next {
            top: auto;
            bottom: 40%;
            margin-top: -28px;
            border-width: 28.5px 0 28.5px 24px;
        }
        

        @media(max-width:600px){
            #fp-nav.fp-left {
                display: none;
                top: 125px;
                left: 2px;
            }
        }
      `}
  </style>
)
